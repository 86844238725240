<template>
  <PageTemplateStaticInfo
    :title="state.title"
    :text="state.text"
  />
</template>

<script setup lang="ts">
const { error } = defineProps({
  error: {
    type: Object,
    required: true,
  },
});

const state = reactive({
  title: 'Something went wrong',
  text: 'An unexpected error occurred. Please try again later.',
});

switch (error.statusCode) {
  case 404:
    state.title = '404 - Page not found';
    state.text = 'The page you are looking for does not exist. Please check the entered URL and try again.';
    break;
  default:
    state.title = `${error.statusCode} - Unexpected error`;
    state.text = 'An unexpected error occurred. Please try again later.';
    break;
}
</script>
