import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexoOBDY93e9EMeta } from "/vercel/path0/pages/login/index.vue?macro=true";
import { default as reset_45passwordXdXGqhiokHMeta } from "/vercel/path0/pages/login/reset-password.vue?macro=true";
import { default as _91id_93Kw8pDxaClKMeta } from "/vercel/path0/pages/prescriptions/[id].vue?macro=true";
import { default as createpI41FH0VbhMeta } from "/vercel/path0/pages/prescriptions/create.vue?macro=true";
import { default as indexe0TBfXSzksMeta } from "/vercel/path0/pages/prescriptions/index.vue?macro=true";
import { default as reportingldlYHKxnK8Meta } from "/vercel/path0/pages/reporting.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as clinicsVWcz6RuZWMeta } from "/vercel/path0/pages/signup/clinic.vue?macro=true";
import { default as identificationlN7wbjqP18Meta } from "/vercel/path0/pages/signup/identification.vue?macro=true";
import { default as indexLAWGSf2MvbMeta } from "/vercel/path0/pages/signup/index.vue?macro=true";
import { default as prescriberhebVUVWOiVMeta } from "/vercel/path0/pages/signup/prescriber.vue?macro=true";
import { default as successG3D1SnK9TzMeta } from "/vercel/path0/pages/signup/success.vue?macro=true";
import { default as terms_45conditionsvAyA1KQpqLMeta } from "/vercel/path0/pages/terms-conditions.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexoOBDY93e9EMeta || {},
    component: () => import("/vercel/path0/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login-reset-password",
    path: "/login/reset-password",
    meta: reset_45passwordXdXGqhiokHMeta || {},
    component: () => import("/vercel/path0/pages/login/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "prescriptions-id",
    path: "/prescriptions/:id()",
    component: () => import("/vercel/path0/pages/prescriptions/[id].vue").then(m => m.default || m)
  },
  {
    name: "prescriptions-create",
    path: "/prescriptions/create",
    component: () => import("/vercel/path0/pages/prescriptions/create.vue").then(m => m.default || m)
  },
  {
    name: "prescriptions",
    path: "/prescriptions",
    component: () => import("/vercel/path0/pages/prescriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "reporting",
    path: "/reporting",
    component: () => import("/vercel/path0/pages/reporting.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/vercel/path0/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "signup-clinic",
    path: "/signup/clinic",
    meta: clinicsVWcz6RuZWMeta || {},
    component: () => import("/vercel/path0/pages/signup/clinic.vue").then(m => m.default || m)
  },
  {
    name: "signup-identification",
    path: "/signup/identification",
    meta: identificationlN7wbjqP18Meta || {},
    component: () => import("/vercel/path0/pages/signup/identification.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    meta: indexLAWGSf2MvbMeta || {},
    component: () => import("/vercel/path0/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: "signup-prescriber",
    path: "/signup/prescriber",
    meta: prescriberhebVUVWOiVMeta || {},
    component: () => import("/vercel/path0/pages/signup/prescriber.vue").then(m => m.default || m)
  },
  {
    name: "signup-success",
    path: "/signup/success",
    meta: successG3D1SnK9TzMeta || {},
    component: () => import("/vercel/path0/pages/signup/success.vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionsvAyA1KQpqLMeta || {},
    component: () => import("/vercel/path0/pages/terms-conditions.vue").then(m => m.default || m)
  }
]